import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import en from './locale/en.json'
import fr from './locale/fr.json'

const i18n = createI18n({
    locale: 'en',
    messages: {
        en: en,
        fr: fr
    }    
})

createApp(App).use(router).use(i18n).mount('#app')