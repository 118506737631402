<template>
    <div class="container">
        <div class="mx-auto pb-3 section-border">
            <nav class="navbar navbar-expand-md">
                <div class="container-fluid">
                    <a href="https://www.tel-synergy.com/"><img src="../assets/telsynergy.png" alt="telsynergy" width="230px"/></a>

                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <ul class="navbar-nav">                            
                            <li class="nav-item">
                                <a class="nav-link" aria-current="page" href="#benefits">{{ $t('menu_1') }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#services">{{ $t('menu_2') }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link me-5" href="#contact">{{ $t('menu_3') }}</a>
                            </li>
                            <li class="nav-item dropdown border-start">                                
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ locale }}
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" >
                                    <li v-for="(locale, index) in locales" :key="index">
                                        <a class="dropdown-item" href="#" @click="setLocale(index)">{{ locale }}</a>
                                    </li>
                                </ul>                                
                            </li>
                        </ul>
                    </div>                    
                </div>
            </nav> 
           
            <div class="row logo">                
                <div class="col text-center"><img src="../assets/businesswoman.jpg" alt="logo" width="100%"></div>
                <div class="logo-text">{{ $t('main_title') }}</div>
            </div>
            <div class="row py-3">
                <div class="col text-center"><h1>{{ $t('heading') }}</h1></div>          
            </div>            
            <div class="row">
                <div class="col-lg p-5 pt-4"><p class="promo-text" v-html="$t('promo_text')"></p></div>
                <div class="col-lg text-center"><img  src="../assets/devices-communicator.png" alt="logo" width="70%"></div>                    
            </div>

            <div class="row px-4 py-4" id="services">
                <template v-for="(category, index) in categories" :key="index">
                    <template v-for="(title, index) in category.category_title" :key="index">
                    <div class="col-md-4">
                        <div class="category text-center mb-1 py-1"><h5 v-html="$t(title)"></h5></div>                        
                        <div class="category-description">
                            <div class="px-3 pt-3 lh-1"><p class="category-text">{{ $t(category.category_desc[index]) }}</p></div>
                            <div class="category-button text-center">
                                <a href="#form"><button type="button" class="btn btn-primary btn-sm">{{ $t('order_category') }}</button></a>
                            </div>
                        </div>                        
                    </div>
                </template>
                </template>
            </div>

             <div class="row">
                <div class="col text-center"><img src="../assets/communicator-go.png" alt="logo" width="80%"></div>
            </div>            
            <div class="row px-3 mt-4 font-weight-bold  text-center" id="benefits">                
                <template v-for="(feature, index) in features" :key="index">
                    <div class="col-lg-3 mb-1">
                        <div class="feature" v-html="$t(feature)"></div>
                    </div>
                </template>
            </div>                
            
            <div class="row section-border mx-2 my-3">
                <div class="col ps-2">
                    <form method="get">
                        <div class="form-group my-1">                        

                            <h5 class="mt-3">{{ $t('form_title') }}</h5>

                            <input type="text" class="form-control form-control-sm mt-2" name="company_name" id="form" :placeholder="$t('company_name')+'*'"  v-model="formData.company_name">                        
                            <p class="text-danger mt-0 ps-2" v-if="error.company_name">{{ error.company_name }}</p>
                            
                            <input type="text" class="form-control form-control-sm mt-2" name="first_name" :placeholder="$t('first_name')+'*'" v-model="formData.first_name">                        
                            <p class="text-danger mt-0 ps-2" v-if="error.first_name">{{ error.first_name }}</p>
                            
                            <input type="text" class="form-control form-control-sm mt-2" name="last_name" :placeholder="$t('last_name')+'*'" v-model="formData.last_name">                        
                            <p class="text-danger mt-0 ps-2" v-if="error.last_name">{{ error.last_name }}</p>
                            
                            <input type="text" class="form-control form-control-sm mt-2" name="business_email" :placeholder="$t('business_email')+'*'" v-model="formData.business_email">                        
                            <p class="text-danger mt-0 ps-2" v-if="error.business_email">{{ error.business_email }}</p>
                            
                            <input type="text" onkeyup="value=value.replace(/[^+?|\d]/g,'')" class="form-control form-control-sm mt-2" name="business_phone" :placeholder="$t('business_phone')+'*'" v-model="formData.business_phone">                        
                            <p class="text-danger mt-0 ps-2" v-if="error.business_phone">{{ error.business_phone }}</p>

                            <h6 class="mt-3">{{ $t('message_title') }}</h6>
                            <textarea class="form-control form-control-sm" id="message" rows="5" :placeholder="$t('message')" v-model="formData.message"></textarea>

                        </div>
                    </form>
                    
                    <vue-recaptcha  class="mt-3"
                        sitekey="6LfQ2DcpAAAAADVB1-XC0X9TOg1Ip0n-pQmQikW-"
                        size="normal"
                        hl="en"
                        @verify="recaptchaVerified">
                    </vue-recaptcha>
                    <p class="text-danger me-3 ps-2" v-if="!captcha_verified">{{ error.captcha }}</p>
                    
                    <button class="btn btn-primary btn-sm my-3" id="submitBtn" @click="submitForm" >{{ $t('submit_request') }}</button>          
                </div>                
            </div>

            <div class="row mx-2 py-4 section-border" id="contact">                    
                <div class="col-lg ps-2 contact">
                    <div class="mb-3 contact-title">{{ $t('contact_info') }}</div>
                    <p>
                        info@tel-synergy.com<br/>
                        {{ $t('toll_free') }}: 1-888-354-7499<br/>
                        4255 Rue St. Catherine West, Westmount, QC
                    </p>
                    <div class="linkedin"><a href="https://www.linkedin.com/company/tel-synergy-network-inc" target="_blank"><img  src="../assets/linkedin.jpg" alt="logo" width="5%"/></a></div>                                        
                </div>                
                <div class="col-lg text-center m-auto"><img  src="../assets/communicator-meeting.png" alt="logo" width="90%"/></div>                                        
            </div>

        </div> <!-- end of section-border -->
    </div>
</template>

<script>
import Axios from 'axios' 
import Swal from 'sweetalert2'
// import VueRecaptcha from 'vue-recaptcha';
// import vue-recaptcha from 'vue-recaptcha';
import vueRecaptcha from 'vue3-recaptcha2';

const locales = {
    en: 'ENGLISH',
    fr: 'FRANÇAIS'
}

export default {
    name: 'Email',      
    components: {
        vueRecaptcha
    },
    setup() {
        return {
            locales: {
                en: locales['en'],
                fr: locales['fr']            
            },
            categories: [
            {
                category_title: [ 'category_1', 'category_2', 'category_3'],
                category_desc: [ 'category_1_desc', 'category_2_desc', 'category_3_desc']
            }],     
            features: ['feature_1', 'feature_2', 'feature_3', 'feature_4', 'feature_5', 'feature_6', 'feature_7', 'feature_8']          
        }
    },
    
    data() {
        return {
            formData: {
                company_name: '',
                first_name: '',
                last_name: '',
                business_email: '',
                business_phone: '',
                number_of_employees_seats: '',
                message: '',
                utm_source: this.$route.query.utm_source
            },
            locale_key: 'en',
            locale: locales.en,            
            error: {},             
            captcha_verified: false
        }
    },  

    methods: {
        setLocale(key){
            this.locale = locales[key]
            this.locale_key = key
            this.$i18n.locale = key
        },
        recaptchaVerified(response)
        {
            if(response){
                this.captcha_verified = true                
            }          
        },        
        submitForm()
        {
            this.error.hasError = false
            this.validateCompanyInput()
            this.validateFirstNameInput()
            this.validateLastNameInput()
            this.validateBusinessEmailInput()
            this.validatePhoneNumberInput()
            this.validateNumberOfEmployeesSeatsInput()

            if (!this.captcha_verified)
            {
                this.error.captcha = this.$i18n.t('err_captcha')
                this.error.hasError = true
            }

            if (!this.error.hasError){
                this.send()
            }                                    
        },

        validateCompanyInput(){            
            this.error.company_name = '';

            if (this.formData.company_name == ''){
                this.error.company_name = this.$i18n.t('err_company_name_required')
                this.error.hasError = true;
            }                
        },       

        validateFirstNameInput(){            
            this.error.first_name = '';

            if(this.formData.first_name == ''){
                this.error.first_name = this.$i18n.t('err_first_name_required')
                this.error.hasError = true;
            }            
        }, 

        validateLastNameInput(){            
            this.error.last_name = '';

            if (this.formData.last_name == ''){
                this.error.last_name = this.$i18n.t('err_last_name_required')                 
                this.error.hasError = true;
            } 
        },

        validateBusinessEmailInput(){            
            this.error.business_email = ''

            if(this.formData.business_email == ''){
                this.error.business_email = this.$i18n.t('err_business_email_required')
                this.error.hasError = true;
            }
            else{
                var pattern = /^\S+@\S+\.\S+$/

                if (!pattern.test(this.formData.business_email)){
                    this.error.business_email = this.$i18n.t('err_business_email_invalid')
                    this.error.hasError = true;
                }
            }                        
        },

        validatePhoneNumberInput(){            
            this.error.business_phone = ''

            if(this.formData.business_phone == ''){
                this.error.business_phone = this.$i18n.t('err_business_phone_required')
                this.error.hasError = true;
            }
            else{                
                var pattern = /^\+?\d+$/

                if(!pattern.test(this.formData.business_phone)){
                    this.error.business_phone = this.$i18n.t('err_business_phone_invalid')
                    this.error.hasError = true;
                }
            }            
        },
        validateNumberOfEmployeesSeatsInput(){            
            this.error.number_of_employees_seats = '';

            if (this.formData.number_of_employees_seats == ''){
                this.error.number_of_employees_seats = this.$i18n.t('err_number_of_employees_seats_required') 
                this.error.hasError = true;
            }
        },

        send(){
            this.formData.utm_source = this.$route.query.utm_source
            Axios.post('api/script/SendEmail.php', {                
                data: this.formData
            })
                .then(response => {            
                    if (response.data.success){
                        Swal.fire({
                            icon: "success",
                            title: this.$i18n.t('email_sent_title'),
                            text: this.$i18n.t('email_sent_text'),
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                    else{
                        Swal.fire({
                        icon: "error",
                        title: this.$i18n.t('err_email_title'),
                        text: this.$i18n.t('err_email_text'),                    
                        });
                    }                
                }).catch(error => {
                    console.log(error);
            });
        }               
    }
}
</script>

<style scoped>
p {
    font-size:10pt;
}

h3{
    font-size: 22pt;
}

.logo{
    position: relative;
}

.category{
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 2px solid lightgray;   
}

.category-description{ 
    width: 100%;
    height: 150px;
    border: 2px solid lightgray;
    border-radius: 20px;
    position:relative;
 } 

.category-button{
    position: absolute;
    top: 65%;
    width:100%;
}

.promo-text{
    font-size: 14pt;
}

.category-text{
    font-size: 13pt;
}

.feature{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #EAEAEA;
    border-radius: 15px;   
    font-size: 12pt;
    height: 60px;
    padding: 20px;
    font-weight: bold;
}

.section-border{
    border: 2px solid lightgray;
}

.linkedin{
    margin-top:200px;
    margin-bottom: 10px;
}

li a.nav-link:hover {
  font-weight: bold; 
  opacity: 1;
} 

li a{
    font-size: 10pt;
    color: #1a3c78;
    font-weight: 600;
    opacity:.5;
}

div.contact p{
    font-size: 12pt;
}

div.contact-title{    
    font-size: 16pt;
    font-weight: bold;
}

@media (max-width: 576px) { 
    .logo-text{
        font-size: 16pt;
        color: white;
        position: absolute;
        top: 50%;
        left: 2%;
    }

    .promo-text{
        font-size: 10pt;
    }

    .category-text{
        font-size: 10pt;
    }

    .menu-logo{
        /* display: inline-block; */
        display:"d-inline-block"
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width:577px) and  (max-width: 768px) { 
    .logo-text{
        font-size: 20pt;
        color: white;
        position: absolute;
        top: 50%;
        left: 2%;
    }  
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width:769px) and ( max-width: 992px) { 
    .logo-text{
        font-size: 26pt;
        color: white;
        position: absolute;
        top: 50%;
        left: 2%;
    }

    .category-description{
        height: 220px;
    }

    .category-button{ 
        top: 75%;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width:993px) and (max-width: 1200px) { 
    .logo-text{
        font-size: 34pt;
        /* font-weight: bold; */
        color: white;
        position: absolute;
        top: 50%;
        left: 2%;
    }

    .category-description{ 
        width: 100%;
        height: 150px;
        border: 2px solid lightgray;
        border-radius: 20px;
        position:relative;
    } 

    .category-button{
        position: absolute;
        top: 70%;
        width:100%;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width:1201px){ 
    .logo-text{
        font-size: 58px;
        color: white;
        position: absolute;
        top: 50%;
        left: 2%;
    }
}
</style>