<template>  
    <router-view/> 
  </template>
  
  <script>
  export default {
    name: 'app',
  }
  </script>
  
  <style>
  body{
      color: #292929;
      font-family: Raleway, "Helvetica Neue", Helvetica;
  }
  </style>