import { createRouter, createWebHistory } from "vue-router";
import Email from "../components/Email.vue";

const routes = [
    {
        path: "/",
        name: "Email",
        component: Email,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router